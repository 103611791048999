import React from 'react';
import { Container, Grid, Link, SvgIcon, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'i18n';
import { useAuthProps } from 'hooks';
import { routes } from 'utils/routing';
import { useSelector } from 'react-redux';
import FacebookIcon from 'components/svg/FacebookIcon.svg';
import InstagramIcon from 'components/svg/InstagramIcon.svg';
import Image from 'next/image';
import { DetectMarketSelectors } from '../../store/selectors';

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: '#4E6DF5',
    padding: theme.spacing(4, 3, 5),
  },
  whiteText: {
    fontWeight: 500,
    fontSize: 11,
    lineHeight: '160%',
    color: '#D1DFFF',
  },
  whiteClickableText: {
    fontWeight: 500,
    fontSize: 11,
    lineHeight: '160%',
    color: '#D1DFFF',
    cursor: 'pointer',
    '&:hover': {
      color: 'white',
    },
  },
  uppercase: {
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: 13,
    lineHeight: '160%',
    color: '#D1DFFF',
  },
  footerBottom: {
    display: 'flex',
    justifyContent: 'center',
    borderTop: '1px solid #CAD1DE',
    backgroundColor: '#4E6DF5',
    padding: theme.spacing(2),
  },
  facebookIcon: {
    color: '#D1DFFF',
    width: 32,
    height: 32,
    borderRadius: 16,
    '&:hover': {
      color: 'white',
      cursor: 'pointer',
    },
  },
  instagramIcon: {
    marginLeft: 8,
    color: '#D1DFFF',
    width: 32,
    height: 32,
    '&:hover': {
      color: 'white',
      cursor: 'pointer',
    },
  },
  contactUs: {
    [theme.breakpoints.down('md')]: {
      paddingTop: 24,
    },
  },
  logoImage: {
    width: 62,
    height: 32,
  },
}));

const helpCenterUrl = 'https://help.jlive.app/';
// const supportUrl = 'support@jlive.app';
const contactUs = 'https://help.jlive.app/contact-support/';
const aboutUsUrl = 'http://info.jlive.app?utm_source=jlivewebsite';

const Footer = () => {
  const { classes } = useStyles();
  const [t] = useTranslation('public');

  const { onLogin, isAuthenticated } = useAuthProps();
  const market = useSelector(DetectMarketSelectors.getSelectedMarket);

  const facebookURL = () => {
    if (market === 'montreal') return 'https://www.facebook.com/jlivemontreal/';
    return 'https://www.facebook.com/jlivedetroit/';
  };

  const instagramURL = () => {
    if (market === 'montreal') return 'https://www.instagram.com/jlivemontreal/';
    return 'https://www.instagram.com/jlivedetroit/';
  };

  const jobsURL = () => {
    if (market === 'montreal')
      return 'https://jobs.jlive.app/jobs?filter=eyJvcmdhbml6YXRpb24udG9waWNzLm1hcmtldCI6WyJNb250cmVhbCJdfQ%3D%3D';
    if (market === 'detroit')
      return 'https://jobs.jlive.app/jobs?filter=eyJvcmdhbml6YXRpb24udG9waWNzLm1hcmtldCI6WyJEZXRyb2l0Il19';
    return 'https://jobs.jlive.app';
  };

  return (
    <>
      <div className={classes.root}>
        <Container maxWidth="lg">
          <Grid container alignItems="center" spacing={3}>
            <Grid item md={3} xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Image
                    className={classes.logoImage}
                    src="/static/svg/jlive-logo/logo-light.svg"
                    alt="logo-light"
                    width={62}
                    height={32}
                  />
                </Grid>
                <Grid item xs={12} md={7}>
                  <Typography variant="caption" className={classes.whiteText}>
                    {t('screen.homepage.footer.description')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md xs={12}>
              <Grid container alignItems="flex-start">
                <Grid item md={3} xs={6}>
                  <Grid container spacing={3}>
                    <Grid item>
                      <Typography variant="body2" className={classes.uppercase}>
                        {t('screen.homepage.footer.aboutUs')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Link
                            href={aboutUsUrl}
                            target="_blank"
                            underline="none"
                            color="inherit"
                            className={classes.whiteClickableText}
                          >
                            {t('common:button.aboutJlive')}
                          </Link>
                        </Grid>
                        <Grid item xs={12}>
                          <Link
                            href={jobsURL()}
                            target="_blank"
                            underline="none"
                            color="inherit"
                            className={classes.whiteClickableText}
                          >
                            {t('common:button.jLiveJobs')}
                          </Link>
                        </Grid>
                        <Grid item xs={12}>
                          <Link
                            href={routes.organizations().link}
                            underline="none"
                            color="inherit"
                            className={classes.whiteClickableText}
                          >
                            {t('common:button.organizations')}
                          </Link>
                        </Grid>
                        <Grid style={{ zIndex: 1 }} item xs={12}>
                          <Link
                            href="https://www.info.jlive.app/press"
                            underline="none"
                            target="_blank"
                            color="inherit"
                            className={classes.whiteClickableText}
                          >
                            {t('common:button.press')}
                          </Link>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={3} xs={6}>
                  <Grid container spacing={3} direction="column">
                    <Grid item>
                      <Typography variant="body2" className={classes.uppercase}>
                        {t('screen.homepage.footer.customerCare')}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Link
                            href={helpCenterUrl}
                            target="_blank"
                            underline="none"
                            color="inherit"
                            className={classes.whiteClickableText}
                          >
                            {t('common:button.helpCenter')}
                          </Link>
                        </Grid>
                        {!isAuthenticated && (
                          <Grid item xs={12}>
                            <Link
                              component="button"
                              onClick={onLogin}
                              underline="none"
                              color="inherit"
                              className={classes.whiteClickableText}
                            >
                              {t('common:button.login')}
                            </Link>
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <Link
                            href={routes.privacyPolicy().link}
                            target="_blank"
                            underline="none"
                            color="inherit"
                            className={classes.whiteClickableText}
                          >
                            {t('common:button.privacyPolicy')}
                          </Link>
                        </Grid>
                        <Grid item xs={12}>
                          <Link
                            href={routes.termsOfUse().link}
                            target="_blank"
                            underline="none"
                            color="inherit"
                            className={classes.whiteClickableText}
                          >
                            {t('common:button.termsOfService')}
                          </Link>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={3} xs={6}>
                  <Grid container spacing={3} className={classes.contactUs}>
                    <Grid item xs={12}>
                      <Typography variant="body2" className={classes.uppercase}>
                        {t('screen.homepage.footer.contactUs')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Link
                        href={contactUs}
                        target="_blank"
                        underline="none"
                        color="inherit"
                        className={classes.whiteClickableText}
                      >
                        {t('screen.homepage.footer.support')}
                      </Link>
                    </Grid>
                    <Grid item xs={12}>
                      <Link href={facebookURL()} target="_blank" underline="none">
                        <SvgIcon
                          className={classes.facebookIcon}
                          component={FacebookIcon}
                          target="_blank"
                          viewBox="0 0 32 32"
                        />
                      </Link>
                      <Link href={instagramURL()} target="_blank" underline="none" style={{ marginLeft: 8 }}>
                        <SvgIcon
                          className={classes.instagramIcon}
                          component={InstagramIcon}
                          target="_blank"
                          viewBox="0 0 32 32"
                        />
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className={classes.footerBottom}>
        <Typography variant="caption" className={classes.whiteText}>
          ⓒ {new Date().getFullYear()} JLive. {t('common:statement.allRightsReserved')}
        </Typography>
      </div>
    </>
  );
};

export default Footer;
